import React, { Fragment, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
//====== custom plugin
import 'style.css';
import { ComboItem } from 'types';
//====

const defaultItemInx  = (items: any, value: string) => items.map(function(e: any) { return e.name; }).indexOf(value)



export default function ComboBox(items: any) {
  const defaultVal = items.items[defaultItemInx(items.items, items.defaultVal)]

  const [selecteditem, setSelecteditem] = useState(defaultVal)
  const [query, setQuery] = useState('')

  const filteredItems =
    query === ''
      ? items.items
      : items.items.filter((item: any) => {
          return item.name.toLowerCase().includes(query.toLowerCase())
        })
  return (
    <Combobox
    value={selecteditem}
    onChange={(val) => {
      items.onChange(val) 
      setSelecteditem(val)
    }}>
      <div className='relative mt-1'>
        <div className='relative w-full cursor-default overflow-hidden text-left shadow-md bg-zinc-800'>
          <Combobox.Input 
          className='w-full border-none py-2 pl-3 pr-10 text-lg leading-5 text-white focus:ring-0 bg-zinc-950'
          onChange={(event) => setQuery(event.target.value)} 
          displayValue={(item: ComboItem) => item.name}/>
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400 text-left"
                  aria-hidden="true"
                />
          </Combobox.Button>
        </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className=' text-left mt-1 max-h-60 w-full overflow-auto bg-zinc-800 py-1 text-base shadow-lg ring-1 ring-black/5'>
              {filteredItems.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-600">
                  No items Found
                </div>
              ) : (
                filteredItems.map((item: any) => (
                  <Combobox.Option 
                    key={item.id}
                    value={item}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-zinc-700 text-white' : 'text-zinc-600'
                      }`
                    }
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium text-white' : 'font-normal'
                          }`}
                        >
                          {item.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-zinc-700'
                            }`}
                          >
                            <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
  )
}
