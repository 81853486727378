import { Transition } from '@headlessui/react';
import React from 'react';
import { LoadingProps } from 'types';

export const Loading: React.FC<LoadingProps> = ({ show, message }) => {
  return (
    <Transition show={show} leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
      <div className="tree-loading-container absolute top-0 left-0 m-0 w-full h-full z-[2000] bg-black bg-opacity-60 flex justify-center items-center gap-2.5">
        <div className="w-5 h-5 border-[10px] border-solid border-neutral-50 border-t-neutral-800 rounded-full animate-spin"></div>
        <>{message !== '' && <span>{message}</span>}</>
      </div>
    </Transition>
  );
};
