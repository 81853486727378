import React from 'react';
import { MessageModalProps, MessageModalControlsProps } from 'types';
import { config } from '@grafana/runtime'; // Access to Dashboard Varibles


export const MessageModalControls: React.FC<MessageModalControlsProps> = ({
  confirm,
  cancel,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  cancelAction = () => {},
  confirmAction = () => {},
}) => {
  return (
    <div className="grid gap-6 grid-cols-2 items-center w-full ">
      {cancel === true && (
        <div className='col-span-1 flex justify-end me-4'>
          <button id="cancelModal" className="bg-transparent hover:bg-red-400 text-red-400 font-semibold hover:text-white py-2 px-4 border border-red-400 hover:border-transparent rounded-full w-1/3" onClick={cancelAction}>
            {cancelText}
          </button>
        </div>
      )}
      {confirm === true && (
        <div className='col-span-1 flex justify-start ms-4'>
          <button id="confirmModal" className="bg-blue-500 hover:bg-blue-700 border border-blue-700 hover: border-transparent text-white font-semibold py-2 px-4 rounded-full w-1/3" onClick={confirmAction}>
            {confirmText}
          </button>
        </div>
      )}
    </div>
  );
};

export const MessageModal: React.FC<MessageModalProps> = ({ show, message, children }) => {
  
  return (
    <>
      {show === true && (
        <div 
        className="tree-modal-container bg-gray-950/20">
          <div className="backdrop"></div>
          <div className={`contentbox ${ config.theme.isDark ? 'bg-gray-800' : 'bg-white'}  ma-4 flex place-content-center grid grid-cols-1 gap-4 h-64`}>
            <p className={`modal-description ${ config.theme.isDark ? 'text-white' : 'text-gray-800'} py-8`}>{message}</p>
            {children}
          </div>
        </div>
      )}
    </>
  );
};
