import React, { useState, useRef, useEffect } from 'react';
import { PopoverMenuProps } from 'types';

//====== custom plugin
import 'style.css';
//====

import { Popover, Transition, Switch } from '@headlessui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import ComboBox from './ComboBox';
import InputRangeSlider from './InputRangeSlider';

const metrics = [
  { id: 1, name: 'ABFR' },
  { id: 2, name: 'BufferHealth' },
  { id: 3, name: 'NatType' },
];

const layout = [
  { id: 1, name: 'Top-Down' },
  { id: 2, name: 'Globular' },
];

export const PopoverMenu: React.FC<PopoverMenuProps> = ({
  controls,
  isAdvanceTier = false,
  graphTotal,
  nodeLimit,
  lockSlide,
  menuRef,
  thresholdValue,
  setThresholdValue = () => {},
  prevThresholdValueRef,
  rangeMin,
  onlyP2PValue,
  layoutAction = () => {},
  metricAction = () => {},
  onlyP2PAction = (filterValue: boolean) => {},
  thresholdAction = () => {},
}) => {
  const [internalThresholdValue, setInternalThresholdValue] = useState(nodeLimit);

  const ref = useRef(null);
  useEffect(() => {
    setInternalThresholdValue(thresholdValue);
  }, [thresholdValue]);

  return (
    <>
      <Popover as="div" className="relative inline-block text-right pa-4 w-full" ref={menuRef}>
        <div className="font-black">
          <Popover.Button
            ref={ref}
            className="inline-flex w-full justify-center rounded-md pe-10 px-4 py-2 text-2xl font-medium text-white "
          >
            {({ open }) => (
              <>
                {open ? (
                  <ChevronDownIcon
                    className=" h-8 w-10 text-2xl text-white font-black focus:text-blue-500"
                    aria-hidden="true"
                  />
                ) : (
                  <ChevronRightIcon
                    className="h-8 w-10 text-2xl text-white font-black focus:text-blue-500"
                    aria-hidden="true"
                  />
                )}
                Controls
              </>
            )}
          </Popover.Button>
        </div>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover.Panel className="absolute left-0 mt-2 p-2 w-96 origin-top-right bg-zinc-900 shadow-lg ring-1 ring-black/5 focus:outline-none">
            {isAdvanceTier && (
              <div className="px-1 pt-2">
                <div className="grid grid-cols-3 items-stretch">
                  <span className="w-full text-start text-wrap pt-2 text-xl"> Metric</span>
                  <div className="col-span-2">
                    <ComboBox items={metrics} defaultVal={controls?.Metric} onChange={metricAction} />
                  </div>
                </div>
              </div>
            )}

            <div className="px-1 pt-2">
              <div className="grid grid-cols-3 items-stretch">
                <span className="w-full text-start text-wrap pt-2 text-xl"> Layout</span>
                <div className="col-span-2">
                  <ComboBox items={layout} defaultVal={controls?.Layout} onChange={layoutAction} />
                </div>
              </div>
            </div>

            <div className="px-1 py-2">
              <div className="grid grid-cols-4 items-stretch">
                <span className="w-full text-start text-wrap pt-2 text-xl col-span-3">Only P2P Connected Nodes</span>
                <div className=" pt-2">
                  {/* TO DO: REFACTOR THIS PART IN OTHER COMPONENT */}
                  <Switch
                    checked={onlyP2PValue}
                    onChange={(value) => {
                      onlyP2PAction(value);
                    }}
                    className={`${
                      onlyP2PValue ? 'bg-blue-600' : 'bg-zinc-600'
                    } relative inline-flex h-6 w-11 items-center rounded-full `}
                  >
                    <span className="sr-only">Enable notifications</span>
                    <span
                      className={`${
                        onlyP2PValue ? 'translate-x-6' : 'translate-x-1'
                      } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                  </Switch>
                </div>
              </div>
            </div>

            {
              // @ts-ignore
              graphTotal > nodeLimit && (
                <div className="px-1 py-2">
                  <div className="grid grid-cols-4 items-stretch">
                    <span className="w-full text-start text-wrap pt-2 text-xl col-span-3">Nodes threshold</span>

                    <input
                      className={
                        'shadow appearance-none border rounded w-full pt-2 text-end text-xl col-span-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:text-white'
                      }
                      placeholder={'' + internalThresholdValue}
                      value={internalThresholdValue}
                      onChange={(e) => {
                        const value = +e.target.value;
                        setInternalThresholdValue(value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          // @ts-ignore
                          // prettier-ignore
                          const value = +e.target.value > graphTotal ? graphTotal : +e.target.value < rangeMin ? rangeMin : +e.target.value;
                          if (value !== thresholdValue) {
                            setInternalThresholdValue(value);
                            prevThresholdValueRef.current = thresholdValue;
                            // @ts-ignore
                            thresholdAction(value);
                            setThresholdValue({ nodesThreshold: value });
                          }
                        }
                      }}
                    />
                    <div className="pt-2 col-span-4">
                      <InputRangeSlider
                        min={rangeMin}
                        max={graphTotal}
                        value={internalThresholdValue}
                        lockSlide={lockSlide}
                        onChange={(value) => {
                          const result = value;
                          if (result) {
                            return setInternalThresholdValue(result);
                          }
                        }}
                        // Still not updating to limit when canceling
                        onMouseUp={(value) => {
                          if (value !== thresholdValue) {
                            prevThresholdValueRef.current = thresholdValue;
                            thresholdAction(value);
                            setThresholdValue({ nodesThreshold: value });
                          }
                        }}
                        performanceMax={nodeLimit}
                      />
                    </div>
                  </div>
                </div>
              )
            }
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  );
};
