import { useState, useEffect, useMemo } from 'react';
import { PanelData } from '@grafana/data';
import { UseGraphDataOptions } from 'data/fields';
import grafanaDataClass from '../grafanaDataClass';
import graphDataClass from '../graphDataClass';
import { format, differenceInCalendarDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

const changeToTimeZone = (timestamp: number, timezone: string) => {
  const timestampDate = new Date(timestamp);
  if (timezone !== 'browser') {
    const timezoneTranform = timezone.toLowerCase() === 'utc' ? timezone.toUpperCase() : timezone;
    return formatInTimeZone(timestampDate, timezoneTranform, 'yyyy-MM-dd HH:mm:ss');
  }

  return format(timestampDate, 'yyyy-MM-dd HH:mm:ss');
};

const useGraphData = (initialData: PanelData, options: UseGraphDataOptions) => {
  const { expandGroup, nodesThreshold, onlyP2PNodes } = options;
  const [graphData, setGraphData] = useState<any>(null);
  const [expandedNodeZoom, setExpandedNodeZoom] = useState('');
  const [highlightedLinks, setHighlightedLinks] = useState(new Set());
  const [highlightedGroups, setHighlightedGroups] = useState<string[]>([]);

  // Check the graph type either advance or not
  const advanceTier = useMemo(() => {
    const grafanaDataInstance = new grafanaDataClass(initialData.series, false);
    return grafanaDataInstance.advanceTier;
  }, [initialData.series]);

  // Treat Grafana data and get the unique NodeId and all the other fields
  const grafanaData = useMemo(() => {
    const grafanaDataInstance = new grafanaDataClass(initialData.series);
    return grafanaDataInstance.grafanaData;
  }, [initialData.series]);

  // Takes grafanaData processed and proceeds to create groups based on threshold and eventually it builds a big model of nodes
  // and links among nodes and groups.
  // This allows to have processed most of data and calculations for the final filtered graph data sent to the graph
  const graphInfo = useMemo(() => {
    return new graphDataClass(grafanaData, nodesThreshold, onlyP2PNodes);
  }, [grafanaData, nodesThreshold, onlyP2PNodes]);

  // Work out the max timestamp from Grafana's data and set it up with the dashboard timezone
  const graphDateInfo = useMemo(() => {
    let graphDate = '';
    const grafanaDataInstance = new grafanaDataClass(initialData.series, false);
    const maxTimestamp = grafanaDataInstance.getMaxTimestampInfo();

    if (maxTimestamp !== 0) {
      graphDate = changeToTimeZone(maxTimestamp, initialData.request?.timezone || 'browser');
    }

    return graphDate;
  }, [initialData.series, initialData.request]);

  // Work out if time range filter is within the last 30 days
  const monthTimeRange = useMemo(() => {
    // calc if grafana filter range is either around last 30 days or not
    const timeRangeFrom = initialData?.timeRange?.from;
    const timeRangeTo = initialData?.timeRange?.to;
    let timeRangeWithin = false;

    if (typeof timeRangeFrom.toDate === 'function' && typeof timeRangeTo.toDate === 'function') {
      const diffTimeRangeFrom = differenceInCalendarDays(new Date(timeRangeFrom.toDate()), new Date());
      const diffTimeRangeTo = differenceInCalendarDays(new Date(timeRangeTo.toDate()), new Date());
      if (diffTimeRangeTo < 0) {
        timeRangeWithin = diffTimeRangeTo >= -30 && diffTimeRangeFrom - diffTimeRangeTo >= -30;
      } else {
        timeRangeWithin = diffTimeRangeFrom >= -30;
      }
    }

    return timeRangeWithin;
  }, [initialData.timeRange]);

  // Proceeds to filter gaph info previously calculated remving nodes that belong to groups and interacting to expand a group sent to this hook
  useEffect(() => {
    graphInfo.expandCollapseGraph(expandGroup ?? '');

    highlightedLinks.clear();
    if (expandGroup !== '') {
      const groupInfo = graphInfo.nodesGroups.find((group) => group.name === expandGroup);
      if (groupInfo) {
        const groupNodesIds = groupInfo.nodes ?? [];
        graphInfo.graphDataFiltered.links.forEach((link: any) => {
          const targetId = link?.target?.id === undefined ? link?.target : link?.target.id;
          if (groupNodesIds.includes(targetId)) {
            highlightedLinks.add(link);
          }
        });

        setExpandedNodeZoom(groupInfo.parent ?? '');
      }
    } else {
      setExpandedNodeZoom('');
    }
    setHighlightedLinks(highlightedLinks);

    setGraphData(() => {
      return {
        nodes: [...graphInfo.graphDataFiltered.nodes],
        links: [...graphInfo.graphDataFiltered.links],
      };
    });

    setHighlightedGroups(() => graphInfo.getHighlightedGroupPerLevel());
  }, [expandGroup, graphInfo, highlightedLinks]);

  return {
    advanceTier,
    grafanaData,
    groups: graphInfo.nodesGroups,
    graphTotalInfo: graphInfo.graphDataTotal,
    graphData,
    graphDateInfo,
    monthTimeRange,
    expandedNodeZoom,
    highlightedLinks,
    highlightedGroups,
    resetExpandedNodeZoom: () => setExpandedNodeZoom(''),
  };
};

export default useGraphData;
