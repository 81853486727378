import React, {ChangeEventHandler, MouseEventHandler} from 'react';


interface Props {
    min?: number;
    max?: number;
    value?: number;
    performanceMax?: number;
    lockSlide?: boolean;
    onChange?(value: number): void
    onMouseUp?(value: number): void
}

const InputRangeSlider: React.FC<Props> = ({min, max, value, performanceMax, lockSlide, onChange, onMouseUp}) => {
    const handleOnChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
      if (!lockSlide) {
        if(!onChange) {return}
        evt.preventDefault()
        const {value} = evt.target
        onChange(+value)
      }
    }
    const handleSubmit: MouseEventHandler<HTMLInputElement> = (evt) => {
        if(!onMouseUp) {return}
        // @ts-ignore
        const {value} = evt.target
        onMouseUp(+value)
    }

    return (
      <div className='cols-span-4'>
        <input
          placeholder='threshold'
          min={min}
          max={max}
          value={value}
          type="range"
          step={1}
          // @ts-ignore
          className={`w-full h-50 ${ value > performanceMax ? 'accent-red-600' : 'accent-blue-600'}`}
          onChange={handleOnChange}
          onMouseUp={handleSubmit}
        />
        <div className='w-full grid grid-cols-2'>
          <span className='text-start'>{min}</span>
          <span>{max}</span>
        </div>
      </div>
    )
}

export default InputRangeSlider
