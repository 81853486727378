import {
  TierDataStructure,
  advanceTierDataStructure,
  liteTierDataStructure,
  LevelsChildrenInterface,
} from './data/fields';
import { DataFrame } from '@grafana/data';
import { globalFlags } from './globalflags';

const cleanedFieldGhostData = [
  {
    nodeName: 'idValues',
    nodeValue: globalFlags.ghostId,
  },
  {
    nodeName: 'sourceValues',
    nodeValue: globalFlags.poiId,
  },
  {
    nodeName: 'abfrValues',
    nodeValue: '0',
  },
  {
    nodeName: 'bufferHealthValues',
    nodeValue: '0',
  },
  {
    nodeName: 'connectionStatus',
    nodeValue: '0',
  },
  {
    nodeName: 'natType',
    nodeValue: '0',
  },
  {
    nodeName: 'uplinkCapacity',
    nodeValue: '0',
  },
  {
    nodeName: 'ip',
    nodeValue: '0',
  },
  {
    nodeName: 'asn',
    nodeValue: '0',
  },
  {
    nodeName: 'asname',
    nodeValue: '0',
  },
  {
    nodeName: 'sessionValues',
    nodeValue: globalFlags.ghostId,
  },
];

export default class GrafanaData {
  advanceTier = false;
  grafanaData: TierDataStructure;
  #dataFrame: DataFrame[] = [];
  #pkFieldName = 'NodeId';
  #childrenIds: string[] = [];
  #childrenIdsTimes = 0;

  constructor(data: DataFrame[], processAllData = true) {
    this.grafanaData = { idValues: [], sourceValues: [] };
    this.#dataFrame = data;
    this.processGrafanaData(processAllData);
  }

  getMaxTimestampInfo() {
    let maxTimestamp = 0;
    const dataFields = this.#dataFrame[0]?.fields || [];
    const cleanDataMask = this.validDataMask(
      this.getFieldDataByName({ json: dataFields, fieldName: this.#pkFieldName })
    );
    let timestampRows: string[] | number[] = this.cleanUpData(
      this.getFieldDataByName({ json: dataFields, fieldName: 'timestamp' }),
      cleanDataMask
    );

    if (timestampRows.length) {
      timestampRows = timestampRows.map(Number);
      maxTimestamp = Math.max(...timestampRows);
    }

    return maxTimestamp;
  }

  private processGrafanaData(processAllData: boolean) {
    const dataFields = this.#dataFrame[0]?.fields || [];
    this.setAdvanceTier(dataFields.length);
    if (processAllData === true) {
      const cleanDataMask = this.validDataMask(
        this.getFieldDataByName({ json: dataFields, fieldName: this.#pkFieldName })
      );
      this.grafanaData = this.getCleanedFieldsByTier(dataFields, cleanDataMask);
    }
  }

  private setAdvanceTier(dataFieldLength: number) {
    this.advanceTier = dataFieldLength > 6;
  }

  private getCleanedFieldsByTier(data: any[], cleanDataMask: any[]) {
    let cleanedFields: TierDataStructure = { idValues: [], sourceValues: [] };
    let tierDataStructure = this.advanceTier ? advanceTierDataStructure : liteTierDataStructure;

    if (tierDataStructure) {
      tierDataStructure.forEach((tierField) => {
        cleanedFields[tierField?.nodeName as keyof typeof cleanedFields] = this.cleanUpData(
          this.getFieldDataByName({ json: data, fieldName: tierField?.fieldName }),
          cleanDataMask
        );
      });
    }

    cleanedFields.sourceValues = this.checkLinks(cleanedFields?.idValues, cleanedFields?.sourceValues);
    //add ghost data to cleaned fields
    cleanedFields = this.addGhostNodeToCleanedFields(cleanedFields);
    const levelsAndChildrenNodes = this.getLevelsChildrenNodes(cleanedFields?.idValues, cleanedFields?.sourceValues);
    cleanedFields.levelValues = levelsAndChildrenNodes.levels;
    cleanedFields.childrenLengthValues = levelsAndChildrenNodes.children;
    cleanedFields.weightValues = this.getNodesWeight(cleanedFields?.idValues, cleanedFields?.childrenLengthValues);

    return cleanedFields;
  }

  private validDataMask(idValues: string[] | undefined) {
    const mask: boolean[] = [];
    const checkedValues: string[] = [];

    if (idValues) {
      idValues.forEach((value) => {
        if (checkedValues.includes(value)) {
          mask.push(false);
          return;
        }
        mask.push(true);
        checkedValues.push(value);
      });
    }

    return mask;
  }

  private chunckArray(arr: any[], size: number) {
    return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));
  }

  private getFieldDataByName({ json, fieldName }: { json: any; fieldName: string }) {
    return json.reduce((newValuesArr: any, currentField: any) => {
      if (currentField.name === fieldName) {
        let currentFieldValues;
        if (!Array.isArray(currentField.values)) {
          const objToArr: any[] = Object.values(currentField.values);
          currentFieldValues = objToArr.length && objToArr[0].length === currentField.values.length ? objToArr[0] : [];
        } else {
          currentFieldValues = currentField.values;
        }
        currentFieldValues = this.chunckArray(currentFieldValues, 50000);
        currentFieldValues.forEach((valuesChunk) => {
          newValuesArr.push(...valuesChunk);
        });
      }
      return newValuesArr;
    }, []);
  }

  private cleanUpData(dataVector: string[] | undefined, mask: boolean[]) {
    return dataVector?.filter((_, index) => mask[index]) || [];
  }

  private checkLinks(idNodes: string[], idSources: string[]): string[] {
    return idSources.map((node: string) => {
      if (idNodes.includes(node) || node === globalFlags.poiId) {
        return node;
      } else {
        return globalFlags.useGhostNode ? globalFlags.ghostId : globalFlags.poiId;
      }
    });
  }

  private addGhostNodeToCleanedFields(cleanedFields: TierDataStructure) {
    if (cleanedFields.sourceValues.includes(globalFlags.ghostId)) {
      cleanedFieldGhostData.forEach((ghostData) => {
        cleanedFields[ghostData?.nodeName as keyof typeof cleanedFields]?.push(ghostData.nodeValue);
      });
    }
    return cleanedFields;
  }

  private getLevelsChildrenNodes(idNodes: string[], idSources: string[]) {
    const levelsChildrenData: LevelsChildrenInterface = { levels: [], children: [] };
    const targetSources = Array.from({ length: idNodes.length }, (_, i) => ({
      source: idSources[i],
      target: idNodes[i],
    }));
    const targetSourcesMap = new Map(targetSources.map(({ source, target }) => [target, source]));
    if (idSources.includes(globalFlags.ghostId) && !idNodes.includes(globalFlags.ghostId)) {
      targetSourcesMap.set(globalFlags.ghostId, globalFlags.poiId);
    }

    idNodes.forEach((node) => {
      levelsChildrenData.levels.push(this.getNodeLevel(node, targetSourcesMap));
      this.#childrenIds = [];
      this.#childrenIdsTimes = 0;
      this.getChildrenIds(node, targetSources);
      levelsChildrenData.children.push(this.#childrenIds.toString());
    });

    return levelsChildrenData;
  }

  private getNodeLevel(nodeId: string, targetSourceMap: Map<string, string>) {
    let currentLevel = globalFlags.poiLevel;
    let currentNodeId = nodeId;
    let findLevel = true;

    while (findLevel) {
      const parent = targetSourceMap.get(currentNodeId);

      if (!parent || parent === globalFlags.poiId) {
        findLevel = false;
      } else {
        currentLevel++;
        currentNodeId = parent;
      }

      if (currentLevel > globalFlags.maxNumberTreeLevels) {
        findLevel = false;
      }
    }

    return currentLevel.toString();
  }

  private getChildrenIds(nodeId: string, targetSources: any[]) {
    if (this.#childrenIdsTimes >= targetSources.length) {
      return;
    }
    this.#childrenIdsTimes++;
    const hasChildren = targetSources.find((targetSource) => targetSource.source === nodeId);

    if (hasChildren) {
      let directChildrenNodes = targetSources
        .filter((targetSource) => targetSource.source === nodeId)
        .map((targetSourceFiltered) => targetSourceFiltered.target);
      this.#childrenIds = [...this.#childrenIds, ...directChildrenNodes];
      directChildrenNodes.map((childNode) => this.getChildrenIds(childNode, targetSources));
    }

    this.#childrenIds = Array.from(new Set(this.#childrenIds));
  }

  private getNodesWeight(idNodes: string[], childrenValues: string[]) {
    const nodesWeight: string[] = [];

    idNodes.forEach((node, i) => {
      let weight = 1;
      const nodeChildren = childrenValues !== undefined ? childrenValues[i] : '';
      const nodeChildrenLength = nodeChildren.trim() !== '' ? nodeChildren.split(',').length : 0;

      weight += nodeChildrenLength;
      nodesWeight.push(weight.toString());
    });

    return nodesWeight;
  }
}
