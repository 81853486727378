import {
  TierDataStructure,
  SubgroupNodes,
  NodesSorted,
  GraphDataInterface,
  advanceTierDataStructure,
  NodeTooltipData,
} from './data/fields';
import { generateTooltip } from 'interface/tooltip';
import { globalFlags } from './globalflags';
import { cloneDeep } from 'lodash';

export default class GraphData {
  nodesGroups: SubgroupNodes[] = [];
  graphDataTotal: GraphDataInterface = { nodes: [], links: [] };
  graphDataFiltered: GraphDataInterface = { nodes: [], links: [] };
  #grafanaData: TierDataStructure;
  #threshold: number = globalFlags.maxNumberTreeNodes;
  #onlyP2P: boolean;

  constructor(grafanaData: TierDataStructure, thresholdLimit: number, onlyP2PNodes: boolean) {
    this.#grafanaData = grafanaData;
    this.#threshold = thresholdLimit;
    this.#onlyP2P = onlyP2PNodes;
    this.processGraph();
  }

  private processGraph() {
    if (this.#onlyP2P === true && this.getTreeLevels() > 1) {
      this.removeNoP2PNodes();
    }
    this.setNodesGroups();
    this.setGraphTotal();
  }

  getTreeLevels() {
    const uniqueLevels = [...new Set(this.#grafanaData.levelValues?.map(Number).sort((a, b) => a - b))];
    return uniqueLevels.length > 0 ? Math.max(...uniqueLevels) : 0;
  }

  removeNoP2PNodes() {
    let cleanedFieldsClone = cloneDeep(this.#grafanaData);
    const cleanedFieldsCloneKeys = Object.keys(cleanedFieldsClone);
    const indexesMask: number[] = [];

    cleanedFieldsClone.idValues.forEach((nodeId, index) => {
      if (index in (this.#grafanaData?.levelValues ?? []) && index in (this.#grafanaData?.weightValues ?? [])) {
        const nodeLevel = (this.#grafanaData?.levelValues ?? [])[index];
        const nodeWeight = (this.#grafanaData?.weightValues ?? [])[index];
        if (nodeLevel !== '1' || (nodeLevel === '1' && nodeWeight !== '1')) {
          indexesMask.push(index);
        }
      }
    });

    if (indexesMask.length) {
      cleanedFieldsCloneKeys.forEach((field) => {
        cleanedFieldsClone[field as keyof TierDataStructure] =
          this.#grafanaData[field as keyof TierDataStructure]?.filter((val, index) => indexesMask.includes(index)) ??
          [];
      });
    }

    this.#grafanaData = cleanedFieldsClone;
  }

  setNodesGroups() {
    if (this.#grafanaData.idValues.length > this.#threshold) {
      this.nodesGroups = this.createSubgroups();
    }
  }

  setGraphTotal() {
    const grafanaDataWithGroups = this.grafanaDataGroups();
    this.graphDataTotal.links = this.setLinksFromSource(grafanaDataWithGroups);
    this.graphDataTotal.nodes = this.setNodesFromIdValues(grafanaDataWithGroups);
  }

  getVisibleGraph(nodes: any[], links: any[], visibleHiddenNodes: { visible: string[]; hidden: string[] }) {
    const hiddenNodes = visibleHiddenNodes.hidden.slice();
    const visibleNodes = visibleHiddenNodes.visible.slice();
    const newNodes = nodes.slice();
    const newLinks = links.slice();
    const visibleNodesData: any[] = [];
    const visibleLinksData: any[] = [];

    hiddenNodes.forEach((nodeId: string) => {
      const newNodesIndex = newNodes.findIndex((newNodeData: any) => newNodeData?.id === nodeId);
      const newLinkIndex = newLinks.findIndex((newLink: any) => {
        const targetId = newLink?.target?.id === undefined ? newLink?.target : newLink?.target.id;
        return targetId === nodeId;
      });

      if (newNodesIndex !== -1 && newLinkIndex !== -1) {
        newNodes.splice(newNodesIndex, 1);
        newLinks.splice(newLinkIndex, 1);
      }
    });

    visibleNodes.forEach((nodeId: string) => {
      const newNodesIndex = newNodes.findIndex((newNodeData: any) => newNodeData?.id === nodeId);
      const newLinkIndex = newLinks.findIndex((newLink: any) => {
        const targetId = newLink?.target?.id === undefined ? newLink?.target : newLink?.target.id;
        return targetId === nodeId;
      });

      if (newNodesIndex === -1) {
        const nodeData = this.graphDataTotal.nodes.find((node: any) => node?.id === nodeId);
        visibleNodesData.push(nodeData);
      }

      if (newLinkIndex === -1) {
        const linkData = this.graphDataTotal.links.find((link: any) => {
          const targetId = link?.target?.id === undefined ? link?.target : link?.target.id;
          return targetId === nodeId;
        });
        visibleLinksData.push(linkData);
      }
    });

    return { nodes: [...newNodes, ...visibleNodesData], links: [...newLinks, ...visibleLinksData] };
  }

  expandCollapseGraph(expandGroup: string) {
    const getVisibleHiddenNodes = this.groupsToRender(expandGroup);

    const newData = this.getVisibleGraph(this.graphDataTotal.nodes, this.graphDataTotal.links, getVisibleHiddenNodes);

    this.graphDataFiltered.nodes = [...newData.nodes];
    this.graphDataFiltered.links = [...newData.links];
  }

  groupsToRender(expandGroup: string) {
    // get visible and hidden groups
    // get visible and hidden nodes

    const visibleNodes: string[] = [];
    const hiddenNodes: string[] = [];
    const expandGroupInfo = this.nodesGroups.find((subgroup) => subgroup.name === expandGroup);
    const noToExpand = expandGroup.trim() === '' || expandGroupInfo === undefined;

    // if there is no group to expand or selected one belongs to spare groups
    if (noToExpand || (expandGroupInfo !== undefined && expandGroupInfo.type === 'spare')) {
      this.nodesGroups.forEach((group) => {
        if (group.type === 'main') {
          // main groups are visible so their nodes hidden
          visibleNodes.push(group.name);
          hiddenNodes.push(...group.nodes);
        } else {
          // spare groups are hidden so their nodes visible
          hiddenNodes.push(group.name);
          visibleNodes.push(...group.nodes);
        }
      });
    } else if (expandGroupInfo !== undefined) {
      // if selected one to be expanded belongs to main nodes created

      // selected group is visible so its nodes hidden
      hiddenNodes.push(expandGroupInfo.name);
      visibleNodes.push(...expandGroupInfo.nodes);

      // all main groups aside from selected one are visible so their nodes hidden
      this.nodesGroups
        .filter((group) => group.type === 'main' && group.name !== expandGroup)
        .forEach((mainGroup) => {
          visibleNodes.push(mainGroup.name);
          hiddenNodes.push(...mainGroup.nodes);
        });

      // calc which one from spare groups has to be collapsed according to close number of nodes of one expanded
      let spareGroupSelected: SubgroupNodes | undefined = undefined; // eventual spare group selected to be collapsed
      let groupDiff = -1;
      this.nodesGroups
        .filter((group) => group.type === 'spare')
        .every((spareGroup) => {
          const diff =
            expandGroupInfo !== undefined ? Math.abs(expandGroupInfo.nodes.length - spareGroup.nodes.length) : 0;

          if (groupDiff === -1 || diff < groupDiff) {
            groupDiff = diff;
            spareGroupSelected = spareGroup;
          }

          return groupDiff > 0;
        });

      // loop beetween all spare groups to set visible and hidden groups and their nodes
      this.nodesGroups
        .filter((group) => group.type === 'spare')
        .forEach((spareGroup) => {
          // if there is a spare group selected, that one has to be visible so its nodes hidden
          if (spareGroupSelected !== undefined && spareGroup.name === spareGroupSelected.name) {
            visibleNodes.push(spareGroupSelected.name);
            hiddenNodes.push(...spareGroupSelected.nodes);
          } else {
            // otherwise other spare groups have to be hidden so their nodes visible
            hiddenNodes.push(spareGroup.name);
            visibleNodes.push(...spareGroup.nodes);
          }
        });
    }

    return { visible: visibleNodes, hidden: hiddenNodes };
  }

  getHighlightedGroupPerLevel(): string[] {
    const highlightedGroups: string[] = [];

    if (this.graphDataFiltered.nodes.length) {
      const currentGroups = this.graphDataFiltered.nodes.filter((node: any) => node.type === 'subgroup');
      const levelsRecorded: number[] = [];

      currentGroups.forEach((group) => {
        if (!levelsRecorded.includes(group.level)) {
          levelsRecorded.push(group.level);
          highlightedGroups.push(group.id);
        }
      });
    }

    return highlightedGroups;
  }

  private nodesSorted(levelList: number[] = []): NodesSorted[] {
    const uniqueLevels =
      levelList.length > 0 ? levelList : [...new Set(this.#grafanaData.levelValues?.map(Number).sort((a, b) => a - b))];
    const indexesMask: number[] = [];

    uniqueLevels.forEach((level) => {
      this.#grafanaData.levelValues?.forEach((levelNode, index) => {
        if (parseInt(levelNode, 10) === level) {
          indexesMask.push(index);
        }
      });
    });

    const nodesSorted = indexesMask.map((index, i) => {
      return {
        target: this.#grafanaData.idValues[index],
        source: this.#grafanaData.sourceValues[index],
        session: this.#grafanaData.sessionValues !== undefined ? this.#grafanaData.sessionValues[index] : '',
        level: this.#grafanaData.levelValues !== undefined ? this.#grafanaData.levelValues[index] : '',
        children:
          this.#grafanaData.childrenLengthValues !== undefined ? this.#grafanaData.childrenLengthValues[index] : '',
        weight: this.#grafanaData.weightValues !== undefined ? this.#grafanaData.weightValues[index] : '',
      };
    });

    return nodesSorted;
  }

  private getInfoGroup() {
    const nodesPerGroup = Math.ceil(Math.sqrt(this.#grafanaData.idValues.length));
    const nodesToGroup = this.#grafanaData.idValues.length - this.#threshold;
    return {
      nodesPerGroup,
      nodesToGroup,
      nodesToGroupPerc: (nodesToGroup * 100) / this.#grafanaData.idValues.length,
      numberOfGroups: Math.ceil(nodesToGroup / nodesPerGroup),
      nodesToGroupWithSpare: Math.ceil(nodesToGroup * 1.5),
    };
  }

  private createSubgroups(): SubgroupNodes[] {
    const levelsList = [...new Set(this.#grafanaData.levelValues?.map(Number).sort((a, b) => a - b))];
    let weightList = [...new Set(this.#grafanaData.weightValues?.map(Number).sort((a, b) => a - b))];
    const nodesList = this.nodesSorted(levelsList);
    const groupsInfo = this.getInfoGroup();
    if (groupsInfo.nodesToGroupPerc > 70) {
      weightList = weightList.reverse();
    }
    const subgroups: SubgroupNodes[] = [];
    let nodesInGroups: string[] = [];
    let subgroupsNumber = 0;
    const minimumNodesPerGroup = groupsInfo.nodesPerGroup / 10 >= 4 ? Math.ceil(groupsInfo.nodesPerGroup / 10) : 4;

    if (groupsInfo.numberOfGroups > 0) {
      weightList.every((weight) => {
        return levelsList.every((level) => {
          const nodesPerLevelAndWeight = nodesList.filter(
            (node) => parseInt(node.level, 10) === level && parseInt(node.weight, 10) === weight
          );

          const nodesPerParent: { parents: string[]; nodes: NodesSorted[][] } = { parents: [], nodes: [] };

          nodesPerLevelAndWeight.forEach((nodeLevelWeight) => {
            if (!nodesPerParent.parents.includes(nodeLevelWeight.source)) {
              nodesPerParent.parents.push(nodeLevelWeight.source);
            }
            const parentIndex = nodesPerParent.parents.findIndex((parent) => parent === nodeLevelWeight.source);
            if (parentIndex !== -1) {
              if (nodesPerParent.nodes[parentIndex] === undefined) {
                nodesPerParent.nodes[parentIndex] = [];
              }
              nodesPerParent.nodes[parentIndex].push(nodeLevelWeight);
            }
          });

          if (nodesPerLevelAndWeight.length > 0 && nodesPerParent.parents.length > 0) {
            nodesPerParent.parents.every((parent, i) => {
              const parentHasMoreChildren = this.getRestChildrenFromNode(
                nodesList,
                parent,
                nodesPerParent.nodes[i].length
              );
              const parentNotGrouped = !nodesInGroups.includes(parent);
              const childrenNotGrouped = this.childrenNotInGroups(nodesPerParent.nodes[i], nodesInGroups);
              const nodesAmount = nodesPerParent.nodes[i].length * weight;

              if (
                parentHasMoreChildren > 0 &&
                childrenNotGrouped === true &&
                parentNotGrouped === true &&
                nodesAmount >= minimumNodesPerGroup
              ) {
                let subgroup: string[] = [];
                const nodesLimit = nodesPerParent.nodes[i].length - 1;

                nodesPerParent.nodes[i].every((node: NodesSorted, j) => {
                  if (subgroup.length >= groupsInfo.nodesPerGroup) {
                    subgroup = [];
                  }

                  let nodeWithChildren = [node.target];
                  const nodeChildren = node.children.trim() !== '' ? node.children.split(',') : [];
                  nodeWithChildren = [...nodeWithChildren, ...nodeChildren];
                  subgroup = [...subgroup, ...nodeWithChildren];

                  if (
                    subgroup.length >= groupsInfo.nodesPerGroup ||
                    (j === nodesLimit && subgroup.length >= minimumNodesPerGroup)
                  ) {
                    subgroupsNumber++;
                    nodesInGroups = [...nodesInGroups, ...subgroup];
                    subgroups.push({
                      name: `${globalFlags.subgroupPrefix}${subgroupsNumber}`,
                      parent,
                      level: level.toString(),
                      nodes: subgroup,
                      type: nodesInGroups.length < groupsInfo.nodesToGroup ? 'main' : 'spare',
                    });
                  }

                  return this.keepCreatingGroups(nodesInGroups.length, groupsInfo.nodesToGroupWithSpare);
                });
              }

              return this.keepCreatingGroups(nodesInGroups.length, groupsInfo.nodesToGroupWithSpare);
            });
          }

          return this.keepCreatingGroups(nodesInGroups.length, groupsInfo.nodesToGroupWithSpare);
        });
      });
    }

    return subgroups;
  }

  private getRestChildrenFromNode(nodeList: NodesSorted[], parent: string, childrenBase: number) {
    const parentChildrenTotal = nodeList.filter((node) => node.source === parent).length;
    return parentChildrenTotal - childrenBase;
  }

  private childrenNotInGroups(nodesPerParent: NodesSorted[], nodesInGroups: string[]) {
    let children: string[] = [];
    nodesPerParent.map((node) => {
      if (node.children !== undefined) {
        const nodeChildren = node.children.trim() !== '' ? node.children.split(',') : [];
        children = [...children, ...nodeChildren];
      }
    });

    return children.every((child) => !nodesInGroups.includes(child));
  }

  private keepCreatingGroups(nodesInGroups: number, nodesLimit: number) {
    return nodesInGroups < nodesLimit;
  }

  private grafanaDataGroups() {
    let cleanedFieldsClone = cloneDeep(this.#grafanaData);

    if (this.nodesGroups.length > 0) {
      const cleanedFieldsCloneKeys = Object.keys(cleanedFieldsClone);

      this.nodesGroups.forEach((subgroup) => {
        cleanedFieldsCloneKeys.forEach((field) => {
          switch (field) {
            case 'idValues':
              cleanedFieldsClone[field]?.push(subgroup.name);
              break;
            case 'sourceValues':
              cleanedFieldsClone[field]?.push(subgroup.parent);
              break;
            case 'sessionValues':
              cleanedFieldsClone[field]?.push(subgroup.name);
              break;
            case 'levelValues':
              cleanedFieldsClone[field]?.push(subgroup.level);
              break;
            case 'childrenLengthValues':
              cleanedFieldsClone[field]?.push(subgroup.nodes.toString());
              break;
            case 'weightValues':
              cleanedFieldsClone[field]?.push((subgroup.nodes.length + 1).toString());
              break;
            default:
              cleanedFieldsClone[field as keyof TierDataStructure]?.push('0');
              break;
          }
        });
      });
    }

    return cleanedFieldsClone;
  }

  private setLinksFromSource(grafanaDataCustom: TierDataStructure) {
    return grafanaDataCustom.sourceValues.map((el, i) => ({
      source: el,
      target: grafanaDataCustom.idValues[i],
    }));
  }

  private setNodesFromIdValues(grafanaDataCustom: TierDataStructure) {
    const advanceGraph = this.isAdvanceGraph(grafanaDataCustom);
    const graphType = advanceGraph ? 'advance' : 'lite';
    let nodes = grafanaDataCustom.idValues.map((el, i) => {
      const childrenIds =
        grafanaDataCustom.childrenLengthValues !== undefined ? grafanaDataCustom.childrenLengthValues[i] : '';
      const nodeLevel =
        grafanaDataCustom.levelValues !== undefined ? parseInt(grafanaDataCustom.levelValues[i], 10) : 0;
      const nodeType = this.getNodeType(el);
      const tooltipInfo = this.getTooltipInfo(grafanaDataCustom, i, nodeType === 'subgroup' ? 'group' : graphType);

      return {
        id: el,
        bh: grafanaDataCustom.bufferHealthValues !== undefined ? grafanaDataCustom.bufferHealthValues[i] : '',
        abfr: grafanaDataCustom.abfrValues !== undefined ? grafanaDataCustom.abfrValues[i] : '',
        nat: grafanaDataCustom.natType !== undefined ? grafanaDataCustom.natType[i] : '',
        session: grafanaDataCustom.sessionValues !== undefined ? grafanaDataCustom.sessionValues[i] : '',
        size: globalFlags.nodeSize,
        level: nodeLevel,
        children: childrenIds.trim() !== '' ? childrenIds.split(',').length : 0,
        collapsed: nodeType === 'subgroup' && childrenIds.trim() !== '',
        type: nodeType,
        tooltip: el === globalFlags.ghostId ? "<p align='center'><b>Ghost Node</b></p> " : generateTooltip(tooltipInfo),
      };
    });

    if (nodes.length > 0) {
      const cdnNode = this.setCDNNode();
      nodes = [...nodes, cdnNode];
    }

    return nodes;
  }

  private getNodeType(targetName: string) {
    let nodeType = 'node';

    if (targetName === globalFlags.poiId) {
      nodeType = 'root';
    } else if (
      globalFlags.subgroupPrefix !== '' &&
      targetName.substring(0, 9).toLowerCase() === globalFlags.subgroupPrefix
    ) {
      nodeType = 'subgroup';
    }

    return nodeType;
  }

  private setCDNNode() {
    return {
      id: globalFlags.poiId,
      bh: '0',
      abfr: '0',
      nat: '0',
      session: globalFlags.poiId,
      size: globalFlags.poiNodeSize,
      level: 0,
      children: 0,
      collapsed: false,
      type: 'root',
      tooltip: "<p align='center'><b>CDN</b></p> ",
    };
  }

  private isAdvanceGraph(grafanaDataCustom: TierDataStructure) {
    const grafanaData = cloneDeep(grafanaDataCustom);
    const grafanaDataKeys = Object.keys(grafanaData);
    const advanceKeys = advanceTierDataStructure.flatMap((field) => field.nodeName);

    return advanceKeys.every((advanceKey) => grafanaDataKeys.includes(advanceKey));
  }

  private getTooltipInfo(
    grafanaDataCustom: TierDataStructure,
    index: number,
    nodeType: 'advance' | 'lite' | 'group'
  ): NodeTooltipData {
    let tooltipInfo: NodeTooltipData = { type: nodeType, nodeId: grafanaDataCustom.idValues[index] };

    if (nodeType === 'group') {
      const childrenIds =
        grafanaDataCustom.childrenLengthValues !== undefined ? grafanaDataCustom.childrenLengthValues[index] : '';
      tooltipInfo['parent'] = grafanaDataCustom.sourceValues[index];
      tooltipInfo['nodesAmount'] = childrenIds.trim() !== '' ? childrenIds.split(',').length : 0;
      if (childrenIds.trim() !== '') {
        const childrenIdsArr = childrenIds.split(',');
        const indexesMask: number[] = [];

        childrenIdsArr?.forEach((childId, index) => {
          const childIndex = grafanaDataCustom.idValues.findIndex((target) => target === childId);
          if (childIndex !== -1) {
            indexesMask.push(childIndex);
          }
        });

        if (indexesMask.length) {
          const bhValues = indexesMask.map((indexMask) => {
            return grafanaDataCustom.bufferHealthValues !== undefined
              ? parseFloat(grafanaDataCustom.bufferHealthValues[indexMask])
              : 0;
          });
          const sumBhValues = bhValues.reduce((acc, curr) => acc + curr, 0);
          if (bhValues.length) {
            tooltipInfo['bh'] = (sumBhValues / bhValues.length).toString();
          }
        }
      }
    } else {
      tooltipInfo['session'] =
        grafanaDataCustom.sessionValues !== undefined ? grafanaDataCustom.sessionValues[index] : '';
      tooltipInfo['bh'] =
        grafanaDataCustom.bufferHealthValues !== undefined ? grafanaDataCustom.bufferHealthValues[index] : '';

      if (nodeType === 'advance') {
        tooltipInfo = {
          ...tooltipInfo,
          abfr: grafanaDataCustom.abfrValues !== undefined ? grafanaDataCustom.abfrValues[index] : '',
          capacity: grafanaDataCustom.uplinkCapacity !== undefined ? grafanaDataCustom.uplinkCapacity[index] : '',
          connectionStatus:
            grafanaDataCustom.connectionStatus !== undefined ? grafanaDataCustom.connectionStatus[index] : '',
          nat: grafanaDataCustom.natType !== undefined ? grafanaDataCustom.natType[index] : '',
          ip: grafanaDataCustom.ip !== undefined ? grafanaDataCustom.ip[index] : '',
          asn: grafanaDataCustom.asname !== undefined ? grafanaDataCustom.asname[index] : '',
        };
      }
    }

    return tooltipInfo;
  }
}
