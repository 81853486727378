/* 
      ---< Tooltip >---

  Generation of a Tooltip with Node information (Check Tier restrictions)

  - NodeId
  - AggregateBufferFillingRate
  - BufferHealth
  - Capacity
  - P2PConnectionStatus
  - NatType
  - IP
  - AsNname

*/
import { NodeTooltipData } from '../data/fields';
import { globalFlags } from '../globalflags';

function bhProgressColor(value: number) {
  for (const index in globalFlags.bufferHealth) {
    if (value < globalFlags.bufferHealth[index]) {
      return `accent-color: ${globalFlags.bufferHealthColor[index]}`;
    }
  }
  return `accent-color: ${globalFlags.bufferHealthColor[globalFlags.bufferHealthColor.length - 1]}`;
}

const setNodeIDFieldValue = (cfIdValue: string, cfSessionValue: string) => {
  const shortIdInit = cfIdValue.slice(0, 6);
  const shortIdEnd = cfIdValue.slice(-6);
  return cfIdValue === cfSessionValue ? 'no-node-id' : `${shortIdInit}..${shortIdEnd}`;
};

function abfrProgressColor(value: number) {
  for (const index in globalFlags.aggregateBufferFillingRate) {
    if (value < globalFlags.aggregateBufferFillingRate[index]) {
      return `accent-color: ${globalFlags.aggregateBufferFillingRateColor[index]}`;
    }
  }
  return `accent-color: ${
    globalFlags.aggregateBufferFillingRateColor[globalFlags.aggregateBufferFillingRateColor.length - 1]
  }`;
}

function getProgressTagHtml(style_fn: (value: number) => string, value: number, max: number) {
  return `<progress style="${style_fn(value)}" value="${value}" max="${max}"> ${value} </progress>`;
}

function getConnectionStatusEmoji(value: string) {
  switch (value) {
    case 'UNKNOWN':
      return '&#x2753'; // Red Octogone
    case 'P2P_CONNECTING':
      return '&#x274E'; // Green X
    case 'P2P_CONNECTED':
      return '&#x2705'; // green V
    case 'NO_PARENT':
      return '&#x1F6D1'; // red X
  }
  return '';
}

function getNatTypeString(value: string) {
  switch (value) {
    case 'UNKNOWN':
      return '&#x2753'; // Red ?
    case 'NON_SYMMETRIC':
      return 'NON_SYMMETRIC';
    case 'SYMMETRIC':
      return 'SYMMETRIC';
    case 'SEQUENTIAL':
      return 'SEQUENTIAL';
  }
  return '';
}

function formatNumberLength(num: string | number, length = 0): string {
  if (!num.toString().includes('.')) {
    return num.toString();
  }

  const integer = num.toString().split('.')[0];
  const decimals = num.toString().split('.')[1];
  if (integer.length > length - 1) {
    return integer;
  }
  if (integer.length > length - 2) {
    return integer + '.' + decimals.slice(0, 1);
  }

  return integer + '.' + decimals.slice(0, 2);
}

function truncateString(inputString: string, maxSize: number): string {
  let tuncatedString = inputString ?? '';

  if (tuncatedString.length > maxSize) {
    tuncatedString = tuncatedString.substring(0, maxSize) + '...';
  }

  return tuncatedString;
}

export function generateTooltip(tooltipInfo: NodeTooltipData) {
  if (tooltipInfo.type === 'group') {
    return groupTooltip(tooltipInfo);
  }

  return nodeTooltip(tooltipInfo);
}

function groupTooltip(tooltipInfo: NodeTooltipData) {
  const { nodeId, parent, nodesAmount, bh } = tooltipInfo;
  let bhInfo = '';
  let nodeID = nodeId;
  if (nodeID.length > 12) {
    nodeID = `${nodeId.slice(0, 6)}..${nodeId.slice(-6)}`;
  }

  const parentNodeValue = parent !== undefined ? parent : '';

  const nodesAmountValue = nodesAmount !== undefined ? nodesAmount : 0;

  if (bh !== undefined) {
    const bhProgressBar = getProgressTagHtml(
      bhProgressColor,
      bh !== undefined ? parseInt(bh, 10) : 0,
      globalFlags.progressBarMaxBufferHealth
    );
    const bhValue = bh !== undefined ? formatNumberLength(bh) : '';

    bhInfo = `<div>- <b>Avg. BH:</b> ${bhProgressBar} ${bhValue} [s]</div>`;
  }

  return `<div>${nodeID}</div>
  <div>- <b>Parent Node:</b> ${parentNodeValue}</div>
  <div>- <b>Grouped Nodes:</b> ${nodesAmountValue}</div>
  ${bhInfo}`;
}

function nodeTooltip(tooltipInfo: NodeTooltipData) {
  const { nodeId, session, bh, abfr, capacity, connectionStatus, nat, ip, asn } = tooltipInfo;
  const isAdvanced = tooltipInfo.type === 'advance';
  let afbrInfo = '',
    advancedInfo = '';

  const shortSessionIdInit = session !== undefined ? session.slice(0, 6) : '';
  const shortSessionIdEnd = session !== undefined ? session.slice(-6) : '';

  // Add BufferHealth
  const bhProgressBar = getProgressTagHtml(
    bhProgressColor,
    bh !== undefined ? parseInt(bh, 10) : 0,
    globalFlags.progressBarMaxBufferHealth
  );
  const bhValue = bh !== undefined ? formatNumberLength(bh) : '';

  const nodeID = setNodeIDFieldValue(nodeId, session ?? '');

  if (isAdvanced) {
    // Add ABFR
    const abfrProgressBar = getProgressTagHtml(
      abfrProgressColor,
      abfr !== undefined ? parseInt(abfr, 10) : 0,
      globalFlags.progressBarMaxaggregateBufferFillingRate
    );
    const abfrValue = abfr !== undefined ? formatNumberLength(parseInt(abfr, 10) / 100, 4) : 0;

    // Add UpLinkCapacity
    const capacityString = capacity !== undefined ? capacity : '';

    // ConnectionStatus Emoji
    const csEmoji = connectionStatus !== undefined ? getConnectionStatusEmoji(connectionStatus) : '';

    // Add NAT
    const natTypeString = nat !== undefined ? getNatTypeString(nat) : '';

    // Add IP
    const ipString = ip !== undefined ? ip : '';
    // Add ASN NAME
    const asnString = asn !== undefined ? truncateString(asn, globalFlags.maxSizeTooltipString) : '';

    afbrInfo = `<div>- <b>ABFR:</b> ${abfrProgressBar} ${abfrValue}</div>`;
    advancedInfo = `<div>- <b>Capacity:</b> ${capacityString}</div>
      <div>- <b>P2PConnectionStatus:</b> ${csEmoji}</div>
      <div>- <b>NatType:</b> ${natTypeString}</div>
      <div>- <b>IP:</b> ${ipString}</div>
      <div>- <b>ASN:</b> ${asnString}</div>`;
  }

  return `<div><b>SessionId:</b> ${shortSessionIdInit}..${shortSessionIdEnd}</div>
  <div>- <b>NodeId:</b> ${nodeID}</div>
  ${afbrInfo}
  <div>- <b>BH:</b> &#160;&#160;${bhProgressBar} ${bhValue} [s]</div>
  ${advancedInfo}`;
}
