/*
  ---< Definition of Global variables >---
*/
import chroma from 'chroma-js';

interface GlobalFlags {
  // POI
  poiId: string;
  poiColor: string;
  poiLevel: number;
  poiNodeSize: number;

  // GhostNode
  ghostId: string;
  ghostColor: string;
  ghostLevel: number;
  ghostNodeSize: number;
  useGhostNode: boolean;

  // General Nodes
  linkColor: string;
  nodeErrorColor: string;
  nodeSize: number;
  linkWidth: number;
  groupLinkWidth: number;

  highlightSize: number;
  nodeHighlightColor: string;

  // ABFR
  aggregateBufferFillingRate: number[];
  aggregateBufferFillingRateColor: string[];
  progressBarMaxaggregateBufferFillingRate: number;

  // BufferHealth
  bufferHealth: number[];
  bufferHealthColor: string[];
  progressBarMaxBufferHealth: number;

  // NatType
  natType: string[];
  natTypeColor: string[];

  // Forces
  chargeForce: number;
  collideLevelWeight: number;
  collideRadius: number;

  linkForce: number;
  linkDistance: number;
  linkDistanceLevelWeight: number;

  velocityDecay: number;
  zoomToFitDelayMs: number;
  cooldownTimeMs: number;

  maxNumberTreeLevels: number;
  nodeRelativeSize: number;

  maxNumberTreeNodes: number;

  firstTimeZoom: boolean;

  // Miscelanea
  maxSizeTooltipString: number;

  subgroupPrefix: string;
}

// https://www.w3.org/TR/css-color-3/
export const globalFlags: GlobalFlags = {
  // POI
  poiId: 'CDN',
  poiColor: chroma('cornflowerblue').hex(),
  poiLevel: 1,
  poiNodeSize: 15,

  // Ghost Node
  ghostId: 'ghostNode',
  ghostColor: chroma('white').hex(),
  ghostLevel: 1,
  ghostNodeSize: 2,
  useGhostNode: true,

  // General Nodes
  linkColor: chroma('white').hex(),
  nodeErrorColor: chroma('white').hex(),
  nodeSize: 2,
  linkWidth: 2,
  groupLinkWidth: 5,

  highlightSize: 7,
  nodeHighlightColor: chroma('orange').hex(),

  // ABFR [x.yy float * 100]
  aggregateBufferFillingRate: [1, 180, 360],
  aggregateBufferFillingRateColor: [
    chroma('red').hex(),
    chroma('darkred').hex(),
    chroma('lightgreen').hex(),
    chroma('teal').hex(),
  ],
  progressBarMaxaggregateBufferFillingRate: 360,

  // BufferHealth [s]
  bufferHealth: [5, 10, 15, 20],
  bufferHealthColor: [
    chroma('red').hex(),
    chroma('darkred').hex(),
    chroma('khaki').hex(),
    chroma('lightgreen').hex(),
    chroma('teal').hex(),
  ],
  progressBarMaxBufferHealth: 30,

  // NatType
  natType: ['NON_SYMMETRIC', 'SYMMETRIC', 'SEQUENTIAL', 'UNKNOWN'],
  natTypeColor: [chroma('teal').hex(), chroma('red').hex(), chroma('fuchsia').hex(), chroma('yellow').hex()],

  // Forces
  chargeForce: -10,

  collideLevelWeight: 2.05,
  collideRadius: 80,

  linkForce: 10,
  linkDistance: 30,
  linkDistanceLevelWeight: 1.5,

  velocityDecay: 0.15,
  zoomToFitDelayMs: 500,
  cooldownTimeMs: 3000,

  maxNumberTreeLevels: 30,
  nodeRelativeSize: 1,

  maxNumberTreeNodes: 5000,

  firstTimeZoom: true,

  // Miscelanea
  maxSizeTooltipString: 16,

  subgroupPrefix: 'subgroup_',
};
