import React from 'react';
import { InfoBoxProps, InfoBoxItem } from 'types';

//====== custom plugin
import 'style.css';
//====

const InfoBoxInfo: React.FC<InfoBoxItem> = ({label, value, showLabel}) => {
    return (
        <li>
            {showLabel && <label>{label}</label>}
            <p title={value}>{value}</p>
        </li>
    )
}

export const InfoBox: React.FC<InfoBoxProps> = ({ items, containerWidth }) => {
    return <>
       {items.length &&  
        <div className={`info-box-panel ${containerWidth <= 400 ? 'info-box-bottom': ''}`}>
            <ul className="info-box-content">
                {items.map(({label, value, showLabel}, index) => {return <InfoBoxInfo key={index} label={label} value={value} showLabel={showLabel} />; })}
            </ul>
        </div>
       } 
    </>
};
