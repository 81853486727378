/*
  ---< GUI related functions >---
*/
// Convert Custom Layout menu options to 3d-graph options
export function getLayout(value: string) {
  switch (value) {
    case 'Top-Down':
      return 'td';
    case 'Globular':
      return null;
  }
  return null;
}

export function getNodesThresholdTitle(title: string, warningIcon: string, renderIcon: boolean) {
  return `${title} ${renderIcon ? warningIcon : ''}`;
}
